<template>
    <div class="p-2" v-if="schedule">
        <div class="d-flex" style="gap:10px 20px">
            <div>
                <img :src="schedule.pet.pet_picture ? schedule.pet.pet_picture : default_img" class="rounded-circle" style="height:80px;width:80px" />
            </div>
            <div class="flex-fill">
                <div class="d-flex justify-content-between">
                    <div>
                        <h3 class="text-white h3" style="margin-bottom: .25em;">{{ schedule.pet.pet_name }}
                        </h3>
                        <div class="badge bg-secondary" v-if="!schedule.is_approved">
                            Waiting for approval
                        </div>
                        <div class="badge bg-danger" v-else-if="schedule.is_approved == 'n'">
                            Rejected
                        </div>
                        <div class="badge bg-success" v-else-if="schedule.is_approved == 'y'">
                            Accepted
                        </div>
                    </div>
                    <router-link :to="`/chats/${schedule.user_id}`" v-if="user.user_id == schedule.walker.user_id">
                        <div class="text-white d-flex align-items-center"
                            style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;padding:.5em 1em">
                            <i class="bx bxs-message-dots" style="font-size: 18px;cursor: pointer;color: #FFAD32;"></i>
                        </div>
                    </router-link>
                </div>
                <div class="d-flex align-items-center text-white" style="gap:10px;margin-top:.5em">
                    <div>Booked at</div>
                    <div>{{ schedule.ws_date }}</div>
                    <div>{{ schedule.ws_time }}</div>
                </div>
                <table class="">
                    <tr>
                        <td><h3 class="text-white h5 mb-0">Breed</h3></td>
                        <td><h3 class="text-white h5 mb-0 text-center" style="width: 50px;">:</h3></td>
                        <td><h3 class="text-white h5 mb-0">{{schedule.pet.pet_breed}}</h3></td>
                    </tr>
                    <tr>
                        <td><h3 class="text-white h5 mb-0">Location</h3></td>
                        <td><h3 class="text-white h5 mb-0 text-center" style="width: 50px;">:</h3></td>
                        <td><h3 class="text-white h5 mb-0">{{schedule.user.user_location}}</h3></td>
                    </tr>
                    <tr>
                        <td><h3 class="text-white h5 mb-0">Service</h3></td>
                        <td><h3 class="text-white h5 mb-0 text-center" style="width: 50px;">:</h3></td>
                        <td><h3 class="text-white h5 mb-0">{{schedule.service}}</h3></td>
                    </tr>
                </table> 
            </div>  
        </div>

        <p class="text-white mt-1">
            {{ schedule.ws_note }}
        </p> 

        <div class="d-flex align-items-center mt-1" style="gap:10px" v-if="user.user_id == schedule.walker.user_id && !schedule.is_approved">
            <div class="text-white btn w-100" @click="change_status('y')"
                style="padding:.5em 3em;gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;">
                <div class="h4 text-white mb-0">Accept</div>
            </div>
            <div class="text-white btn w-100" @click="change_status('n')"
                style="padding:.5em 3em;gap:10px;background-color: #C10000;border: 2px solid #FFAD32;border-radius: 20px;">
                <div class="h4 text-white mb-0">Reject</div>
            </div>
        </div>

    </div>
</template>

<script>

import store from '@/store'
import Cookies from 'universal-cookie'

let cookies = new Cookies()

export default {
    computed: {
        user() {
            return store.state.auth.USER
        },
        schedule(){
            return store.state.walker.SCHEDULE_INFO
        }
    },
    watch: {
        
    },
    methods: {
        change_status(status){
            let payload = new FormData()
            payload.append('id', this.id)
            payload.append('inp[is_approved]', status)

            store.dispatch('walker/SaveSchedule', payload).then((res) => { 
                store.dispatch('walker/GetScheduleDetail', this.id)
            }) 
        },
    },
    mounted() {
        store.dispatch('walker/GetScheduleDetail', this.id)
    },
    data() {
        return {
            id: this.$route.params.id,
            default_img: 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png'
        }
    }
}

</script>